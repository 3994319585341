import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import ContactHeader from "../components/contactheader"

import FB from "../images/facebook-icon.svg"
import WEB from "../images/web-icon.svg"
import Insta from "../images/insta.svg"
import mail from "../images/mail.svg"
import jm from "../images/jm.svg"
import sviews from "../images/s-views.svg"
import Visuals from "../images/2910-logo.png"

import jung from "../images/m-jung.png"
import weierich from "../images/m-weierich.png"
import weber from "../images/m-weber.png"
import wild from "../images/markus.png"
import brueckner from "../images/j-brueckner.png"
import sm from "../images/sm-bb-logo.jpg"

import Footer from "../components/footer"
import Newsletter from "../components/newsletter"

const AboutPage = props => (
  <Layout>
    <SEO
      title="Über Uns | helpyourlocalstore"
      description="„help your local store“ soll auf Liefer- und Abholangebote in Sindelfingen und Böblingen während der Corona-Zeit aufmerksam machen und ist für die Restaurants sowie für alle Nutzer komplett kostenfrei."
    />
    <ContactHeader link="/#newsletter-about" />
    <Nav />
    <div className="grid-container">
      <div className="abschnitt">
        <h2>Warum diese Aktion?</h2>
        <p>
          Die Sindelfinger und Böblinger Gastronomiebetriebe bilden einen wesentlichen Bestandteil der städtischen
          Kultur- und Lebenswelt. Durch die derzeitige „Corona Krise“ mussten viele dieser wunderbaren Etablissements
          vorübergehend die Pforten schließen.{" "}
        </p>
        <p>
          Unser Anliegen mit dieser Aktion ist es daher, die Gastronomiebetriebe im Landkreis Böblingen in diesen
          schweren Zeiten zu unterstützen. Durch die gemeinsame Aktion „help your local store“ soll auf Liefer- und
          Abholangebote aufmerksam gemacht werden. In Form einer digitalen Datenbank werden alle Informationen zu den
          Restaurants und den jeweiligen Dienstleistungsangeboten sichtbar für die Sindelfinger und Böblinger Bürger
          aufgelistet.
        </p>
        <p>
          <strong>
            Wichtig: Die ganze Aktion ist für die Restaurants sowie für alle Nutzer komplett kostenfrei und dient dem
            Erhalt der lokalen Gastronomiebetriebe. Wir verdienen an dieser Aktion nichts und möchten lediglich unsere
            persönlichen Fähigkeiten dafür einsetzen, die lokalen Gastronomien mit unseren Möglichkeiten zu unterstützen
            und allen Nutzern einen simplen Überblick darüber geben, welche Gastronomien während dieser schweren Zeit
            welche Angebote offerieren, um diese mit Bestellungen zu unterstützen.
          </strong>
        </p>
        <p>Wir wünschen Ihnen beste Gesundheit und hoffen dieses Angebot erweist sich als hilfreich.</p>
      </div>
      <div className="abschnitt">
        <h2>Wer ist daran beteiligt?</h2>
        <div className="abschnitt">
          <h2 className="team-center">Das Team</h2>
          <div className="grid-x grid-margin-x align-center">
            <div className="large-3 small-6  cell">
              <div className="team">
                <img src={jung}></img>
                <h3>Max Jung</h3>
              </div>
            </div>
            <div className="large-3 small-6  cell">
              <div className="team">
                <img src={weierich}></img>
                <h3>Michael Weierich</h3>
              </div>
            </div>
            <div className="large-3 small-6  cell">
              <div className="team">
                <img src={weber}></img>
                <h3>Michael Weber</h3>
              </div>
            </div>
            <div className="large-3 small-6  cell">
              <div className="team">
                <img src={wild}></img>
                <h3>Markus Wild</h3>
              </div>
            </div>
            <div className="large-3 small-6  cell">
              <div className="team">
                <img src={brueckner}></img>
                <h3>Jan Brückner</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="abschnitt-small">
          <div className="img-holder">
            <img className="abschnitt-small__image" src={sviews}></img>
          </div>{" "}
          <h3>Sindelfingen Views</h3>
          <p>
            Sindelfingen Views, der Instagram Kanal, der Fotokunst mit fundiertem, historischem Wissen verknüpft und die
            vielen Facetten unserer Heimat gekonnt in Szene setzt.{" "}
          </p>
          <p>
            Unser Ziel ist es, nicht nur Schnappschüsse aus Sindelfingen und Umgebung zu posten, sondern auch das Ganze
            mit historischen Fakten zu unterlegen, Edutainment also. Dabei legen wir uns keine Ketten an, indem wir uns
            auf ein bestimmtes Ressort festlegen: Egal ob Veranstaltungen, die wunderschöne Altstadt, oder Kuriositäten:
            Sindelfingen Views ist dabei und versucht interessante Fakten mitzuliefern. Es ist uns eine
            Herzensangelegenheit, unseren Followern die Stadt Sindelfingen und den Landkreis Böblingen in einem neuen
            Licht zu präsentieren und schmackhaft zu machen.
          </p>
          <a target="_blank" href="https://www.facebook.com/sindelfingenviews/">
            <img className="abschnitt-small__icons" src={FB}></img>
          </a>
          <a target="_blank" href="https://www.instagram.com/sindelfingen_views/">
            <img className="abschnitt-small__icons" src={Insta}></img>
          </a>
        </div>
        <div className="abschnitt-small">
          <div className="img-holder">
            <img className="abschnitt-small__image" src={jm}></img>
          </div>

          <h3>jmmedia</h3>
          <p>
            Wir von jmmedia, sind ein kleines aber hochmotiviertes Unternehmen, das sich auf Webgestaltung spezialisiert
            hat. jmmedia steht für die Kombination aus höchster Qualität, außergewöhnlichem Design und bester
            Performance. Viel wichtiger ist jedoch, dass wir der Ansicht sind, dass Digitalisierung ein Recht und kein
            Luxus ist. Wir haben immer die neuesten Trends im Bereich Webdesign und Social Media im Blick und stellen
            die Wünsche unserer Kunden an erste Stelle. Unsere Leidenschaft sind schnelle und effiziente Designlösungen
            im Bereich Webentwicklung und exzellenter Service. Deshalb ist es unser Anspruch, Ihnen die bestmögliche
            Kombination aus beidem zu bieten. Wenn Sie auf der Suche nach etwas Neuem sind, sind Sie hier genau richtig.
            Mit viel Tatkraft und Innovationsgeist möchten wir unseren Kunden etwas ganz Besonderes bieten. Dabei stehen
            Ihre Wünsche ganz oben auf unserer Prioritätenliste. Als echte Sindelfinger und Böblinger möchten wir uns in
            dieser Zeit mit unserem Wissen und unseren Ressourcen am gemeinschaftlichen Gedanken des Erhalts der lokalen
            Unternehmen beteiligen und haben uns deshalb dazu entschlossen diese Plattform zusammen mit vielen weiteren
            wunderbaren und hilfsbereiten Menschen ins Leben zu rufen, damit wir alle uns auch in Zukunft an einer so
            großen Vielfalt an kulturellen Essenserlebnissen und anderen Dienstleistungen aus der Umgebung erfreuen
            können, die eventuell momentan in einer sehr schwierigen Situation sind.
          </p>
          <a target="_blank" href="mailto:info@jmmedia.design">
            <img className="abschnitt-small__icons" src={mail}></img>
          </a>
        </div>
        <div className="abschnitt-small">
          <div className="img-holder">
            <img className="abschnitt-small__image" src={Visuals}></img>
          </div>

          <h3>2910visuals</h3>
          <p>
            2910visuals alias Michael Weierich ist der Fotograf hinter Sindelfingen Views und ist Selbstständig im
            Bereich Filmproduktion und Fotografie. Er ist gebürtiger und wohnhafter Sindelfinger, studiert derzeit
            Kommunikationswissenschaft an der Universität Hohenheim und ist in dem Projekt „helpyourlocalstore“ für die
            Organisation und das Marketing verantwortlich.
          </p>
          <p>
            70% des Erkennens und Erinnerns läuft über visuelle Reize, denn das Bild hat wesentlich mehr
            Kommunikationskraft als das Wort. Ob die eigenen Homepage, grafische Werbemittel wie Flyer oder die
            Auftritte in sozialen Netzwerken – an ansprechenden und hochwertigen Bildern und Fotos kommt man heutzutage
            nicht vorbei, wenn man sein Unternehmen gegenüber seinen Kunden präsentieren will.
          </p>
          <p>
            Ganz getreu dem Motto „ein Bild sagt mehr als tausend Worte“ können Sie auf der nachfolgenden Homepage einen
            Eindruck von seiner Arbeit gewinnen:
          </p>
          <a target="_blank" href="https://www.instagram.com/2910visuals/">
            <img className="abschnitt-small__icons" src={Insta}></img>
          </a>
          <a target="_blank" href="https://www.behance.net/michaelweierich">
            <img className="abschnitt-small__icons" src={WEB}></img>
          </a>
          <a target="_blank" href="mailto:michaelweierich@outlook.com">
            <img className="abschnitt-small__icons" src={mail}></img>
          </a>
        </div>
        <div className="abschnitt-small">
          <h2>In Kooperation mit</h2>
          <div className="img-holder">
            <img className="abschnitt-small__image" src={sm}></img>
          </div>

          <h3>Stadtmarketing Böblingen</h3>
          <p>
            Wo arbeitet und wohnt es sich am besten? Wo kaufen wir ein, wo verbringen wir unsere Freizeit? – Natürlich
            in Böblingen! Ziel des Stadtmarketing ist, das Image Böblingens als lebendige und innovative Stadt zu
            festigen und die Identifikation der Böblinger mit ihrer Stadt zu stärken.
          </p>
          <p>
            Wir möchten unsere Böblinger Einzelhändler und Gastronomen gerne in dieser schwierigen Zeit unterstützen und
            die Bürgerinnen und Bürger auf die Liefer- oder Abholservice aufmerksam machen. In dieser Zeit sollten wir
            alle zusammenhalten und uns gegenseitig unterstützen! Kaufen Sie so wenig wie möglich online und helfen Sie
            den lokalen Unternehmen!
          </p>
          <a target="_blank" href="https://www.boeblingen.de/start/WirtschaftEntwicklung/Stadtmarketing.html">
            <img className="abschnitt-small__icons" src={WEB}></img>
          </a>
        </div>
      </div>
    </div>
    <div id="newsletter-about">
      <Newsletter mode="full" />
    </div>
    <Footer />
  </Layout>
)

export default AboutPage
